import { AxiosResponse } from "axios";
import { clientTypes, esignTypes, organizerTypes, preparerMessageTypes } from "../../actionTypes";
import { loaderAction } from "./../loaderAction";
import { GroupType, UploadMethod } from "./../../../models/common/enums";
import { IForm, ISignerDocumentModel, EngagementLetterFormData, IClientInfo, FormGroup, SignerDocumentModel } from "./../../../models";
import { ServiceFactory } from "../../../core/services/dataAccess/factory/ServiceFactory";
import { IFileUtilities } from "../../../core/utilities";
import { container, TYPES } from "../../../core/startup";
import { Logger } from "../../../App";
import { DisplayDownloadFile } from "common/DisplayDownloadFile";

const FileUtilities = container.get<IFileUtilities>(TYPES.IFileUtilities);

export const esignAction = {
    getEngagementDocument: (clientId: string, forceRefresh?: boolean, successCallback?: any) => async (dispatch: any, getState: any) => {
        const state = getState();
        if (!forceRefresh && state.engagementLetterDocument && state.engagementLetterDocument.id > 0) {
            dispatch({ type: esignTypes.enagementLetterDataCache, payload: state.engagementLetterDocument });
            successCallback && successCallback(state.engagementLetterDocument);
            return;
        }
        return await ServiceFactory.GetEsignService()
            .getEngagementDocument(clientId)
            .then(function (response: AxiosResponse<ISignerDocumentModel>) {
                if (response.data.uploadMethod == UploadMethod.Batch) {
                    response.data.formGroup?.forEach(function (formGroup: any) {
                        formGroup?.forms?.forEach(function (form: any) {
                            if (form?.formData?.controlValues.length <= 0) {
                                form.formData = EngagementLetterFormData.createNullObject([]);
                            }
                        });
                    });

                    const elFormGroup: FormGroup | undefined = response.data.formGroup.find(
                        (e: any) => e.type == GroupType.Engagement);

                    if (!elFormGroup) {
                        dispatch({
                            type: esignTypes.enagementLetterData,
                            payload: response.data
                        });
                        successCallback && successCallback(response.data);
                        return;
                    }

                    return ServiceFactory.GetEsignService()
                        .getEngagementLetterFormDataLink(clientId)
                        .then(function (enagementLetterSasLinkResponse: AxiosResponse<any>) {
                            FileUtilities.downloadObject(enagementLetterSasLinkResponse.data.sas).then(
                                (data) => {
                                    data.forms.forEach(function (form: IForm) {
                                        const tmpForm: IForm | undefined = elFormGroup?.forms?.find(
                                            (x) => x.pageNo == form.pageNo
                                        );

                                        if (tmpForm) {
                                            tmpForm.formData = form.formData;
                                        }
                                    });

                                    elFormGroup.forms.forEach(function (form: any) {
                                        // sort for next naviagation

                                        if (form.formData && form.formData.controlList) {
                                            form.formData.controlList = form.formData.controlList
                                                .sort(
                                                    (x: any, y: any) =>
                                                        x.boundingRectangle.top - y.boundingRectangle.top
                                                )
                                                .reverse();
                                        }
                                    });

                                    dispatch({
                                        type: esignTypes.enagementLetterData,
                                        payload: response.data
                                    });

                                    successCallback && successCallback(response.data);
                                },
                                (error: string) => {
                                    dispatch({
                                        type: esignTypes.enagementLetterData,
                                        payload: state.engagementLetterDocument
                                    });
                                }
                            );
                        })
                        .catch(function (error: any) {
                            dispatch({
                                type: esignTypes.enagementLetterRequestFailure,
                                payload: state.engagementLetterDocument
                            });
                        });
                } else {
                    dispatch({
                        type: esignTypes.enagementLetterData,
                        payload: response.data
                    });
                    successCallback && successCallback(response.data);
                }
            })
            .catch(function (error: any) {
                Logger.trackError(
                    `getEngagementDocument method failed for clientid: ${clientId} due to ${error?.message}`
                );
                dispatch({ type: esignTypes.enagementLetterRequestFailure, data: state.engagementLetterDocument });
            });
    },
    submitSignedDocument:
        (
            clientId: string,
            signedDocument: ISignerDocumentModel,
            successCallback?: () => void,
            failureCallback?: () => void,
            failureCallbackOnConflict?: () => void
        ) =>
            async (dispatch: any) => {
                dispatch(loaderAction.start());
                await ServiceFactory.GetEsignService()
                    .submitSignedDocument(clientId, signedDocument, successCallback, failureCallback)
                    .then(function (response: AxiosResponse<any>) {
                        dispatch(loaderAction.stop());
                        if (response.data == '') {
                            successCallback && successCallback();
                        }
                        else {
                            failureCallback && failureCallback();
                        }
                    })
                    .catch(function (error: any) {
                        if (error.response?.status === 409) {
                            failureCallbackOnConflict && failureCallbackOnConflict();
                        }
                        else {
                            Logger.trackError(
                                `submitSignedDocument method failed for clientid: ${clientId} due to ${error?.message}`
                            );
                            dispatch(loaderAction.stop());
                            failureCallback && failureCallback();
                        }

                    });
            },

    skipEngagementSign:
        (
            clientId: string,
            signedDocument: SignerDocumentModel,
            successCallback?: () => void,
            failureCallback?: () => void
        ) =>
            async (dispatch: any) => {
                dispatch(loaderAction.start());
                await ServiceFactory.GetEsignService()
                    .skipEngagementSign(clientId, signedDocument)
                    .then(function (response: AxiosResponse<any>) {
                        successCallback && successCallback();
                        dispatch(loaderAction.stop());
                    })
                    .catch(function (error: any) {
                        Logger.trackError(
                            `skipEngagementSign method failed for clientid: ${clientId} due to ${error?.message}`
                        );
                        dispatch(loaderAction.stop());
                        failureCallback && failureCallback();
                    });
            },

    getDocumentStatusAndSourceFileSettings: (
        clientId: string,
        callback?: any) => async (dispatch: any) => {
            try {
                const data = await ServiceFactory.
                    GetEsignService().
                    getDocumentStatusAndSourceFileSettings(clientId);

                if (callback) {
                    callback(data);
                }

            }
            catch (error: any) {
                Logger.trackError(
                    `getDocumentStatusAndSourceFileSettings method failed for clientid: ${clientId} due to ${error?.message}`
                );
                console.log(error);
            }
        },
    getOrganizerMetadata: (
        clientId: string,
        callback?: any) => async (dispatch: any, getState: any) => {
            try {
                const data = await ServiceFactory.
                    GetEsignService().
                    getOrganizerMetadata(clientId);
                if (callback) {
                    callback(data);
                }
                dispatch({
                    type: organizerTypes.organizerMetaData,
                    payload: data.data
                });
            } catch (error: any) {
                Logger.trackError(`getOrganizerMetadata method failed for clientid: ${clientId} due to ${error?.message}`);
                console.log(error);
            }
        },
    updateSpouseEmail:
        (clientId: string,
            clientInfo: IClientInfo,
            successCallback?: () => void, failureCallback?: () => void) =>
            async (dispatch: any) => {
                dispatch(loaderAction.start());

                await ServiceFactory.GetEsignService()
                    .updateSpouseEmail(clientId, clientInfo)
                    .then(function (response: AxiosResponse<any>) {
                        dispatch(loaderAction.stop());
                        successCallback && successCallback();
                    })
                    .catch(function (error: any) {
                        Logger.trackError(
                            `updateSpouseEmail method failed for clientid: ${clientId} due to ${error?.message}`
                        );
                        dispatch(loaderAction.stop());
                        failureCallback && failureCallback();
                    });
            },

    getSpouseInfo: (
        clientId: string,
        forceRefresh?: boolean) => async (dispatch: any, getState: any) => {
            const state = getState();

            if (!forceRefresh && state.clientInfo && state.clientInfo.id > 0) {
                dispatch({ type: clientTypes.clientDataCache, payload: state.clientInfo });
                return;
            }

            return await ServiceFactory.GetEsignService()
                .getSpouseInfo(clientId)
                .then(function (response: AxiosResponse<IClientInfo>) {
                    dispatch({
                        type: clientTypes.clientData,
                        payload: response.data
                    });
                })
                .catch(function (error: any) {
                    Logger.trackError(`getSpouseInfo method failed for clientid: ${clientId} due to ${error?.message}`);
                    dispatch({ type: clientTypes.clientData, payload: state.clientInfo });
                });
        },
    getEngagemementLetterDownloadLink: (
        clientId: string,
        callback?: (link: any) => void) => async (dispatch: any) => {
            try {
                const data = await ServiceFactory.
                    GetEsignService().
                    getEngagemementLetterDownloadLink(clientId);
                if (callback) {
                    callback(data);
                }
            } catch (error: any) {
                Logger.trackError(
                    `getEngagemementLetterDownloadLink method failed for clientid: ${clientId} due to ${error?.message}`
                );
                console.log(error);
            }
        },
    getPreparerMessage: (
        clientId: string,
        callback?: (link: any) => void) => async (dispatch: any, getState: any) => {
            try {
                const data = await ServiceFactory.GetEsignService().getPreparerMessage(clientId);
                if (callback) {
                    callback(data);
                }
                dispatch({
                    type: preparerMessageTypes.preparerMessage,
                    payload: data.data
                });
            }
            catch (error: any) {
                Logger.trackError(
                    `getPreparerMessage method failed for clientid: ${clientId} due to ${error?.message}`
                );
                console.log(error);
            }
        },
    getSignedSignatureDocuments: (clientId: string): any => async () => {

        return await ServiceFactory.GetEsignService().getSignedSignatureDocuments(clientId).
            then(function (response: any) {

                const contentDisposition = response.headers["content-disposition"];
                const fileNameMatch = contentDisposition ? /filename="?([^"]*)"?;/g.exec(contentDisposition) : undefined;
                if (fileNameMatch && fileNameMatch.length > 1) {
                    let fileName = fileNameMatch[1];
                    let displayDownloadFile = new DisplayDownloadFile();
                    displayDownloadFile.showFile(response.data, fileName);
                }
            })
            .catch(function (error: any) {
                console.log(`downloadAllVouchers failed with error ${error.message} for client: ${clientId}`);
            });
    },
    resetStateData: (
        ) =>  (dispatch: any) => {
            dispatch({
                type: esignTypes.resetStateData
            });
        }   
};
