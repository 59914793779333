import { esignTypes } from "../../../redux/actionTypes/";
import { ISignerDocumentModel,SignerDocumentModel } from "./../../../models/esign/signDocument";

const initialState: ISignerDocumentModel = SignerDocumentModel.createNullObject();

export const engagementLetterDocument = (state = initialState, action: any) => {
    switch (action.type) {
        case esignTypes.enagementLetterData:
        case esignTypes.enagementLetterDataCache:
            return action.payload;
        case esignTypes.resetStateData:
            return initialState;    
        default:
            return state;
    }
};

